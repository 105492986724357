<template>
    <v-col cols="12" lg="3" class="px-0 px-lg-6 py-6 py-lg-0">
        <v-hover
            v-slot="{ hover }"
        >
            <v-row class="step-button">
                <!-- STEP NUMBER -->
                <v-col align-self="center" cols="2" class="grayBackground">
                    <h4 class="step-label">
                        {{ stepNumber.toString() }}
                    </h4>
                </v-col>

                <!-- STEP LABEL -->
                <v-col align-self="center" cols="10" :class="finishedStep ? 'redBackground' : hover ? 'grayBackground' : 'whiteBackground'">
                    <h4 class="step-label">
                        {{ stepLabel }}
                    </h4>
                </v-col>

            </v-row>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    name: "StepsWidget",
    props: {
        stepNumber: Number,
        stepLabel: String,
        finishedStep: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style scoped>
.step-label {
    font-family: 'Century Gothic Bold', sans-serif;
    text-align: center;
}

.step-button {
    box-shadow: 0 5px 8px -3px gray;
}

.grayBackground {
    border: 2px solid #3D3533;
    background-color: #3D3533;
    color: #FFF;
}

.redBackground {
    background-color: #EE403D;
    border: 2px solid #EE403D;
    cursor: pointer;
    color: #FFF;
}

.whiteBackground {
    background-color: #FFF;
    border: 2px solid #3D3533;
    cursor: pointer;
}
</style>