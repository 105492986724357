<template>
    <v-col no-gutters class="noSpace no-gutters pa-0">
        <!-- CALCULATOR SCREEN TITLE -->
        <v-row class="no-gutters noSpace mt-12 mt-lg-16 pt-8 px-8" justify="center" align="center">
            <v-col class="noSpace no-gutters">
                <p class="centuryGothicBold screenTitle">CALCULADORA DE PRODUCTO MEZCLA BRAVA</p>
            </v-col>
        </v-row>

        <!-- CALCULATOR STEPS WIDGETS LIST -->
        <v-row no-gutters class="noSpace no-gutters mt-0 mt-lg-4 px-16">
            <!-- STEP #1 -->
            <StepsWidget
                :step-number="1"
                step-label="¿Qué quieres hacer?"
                :finished-step="finishedOne"
            ></StepsWidget>

            <!-- STEP #2 -->
            <StepsWidget
                :step-number="2"
                step-label="Selecciona un producto"
                :finished-step="finishedTwo"
            ></StepsWidget>

            <!-- STEP #3 -->
            <StepsWidget
                :step-number="3"
                step-label="¿Cuánto quieres hacer?"
                :finished-step="finishedThree"
            ></StepsWidget>

            <!-- STEP #4 -->
            <StepsWidget
                :step-number="4"
                step-label="Material Necesario"
                :finished-step="finishedFour"
            ></StepsWidget>
        </v-row>

        <!-- CALCULATOR STEPS TABS -->
        <v-row class="no-gutters noSpace questionsSpace">
            <v-col>
                <!-- STEP #1 TAB -->
                <Question_1
                    v-if="currentQuestion <= 1"
                    :options="firstStepOptions"
                    @step-one-answer="firstStepAnswerSelected"
                ></Question_1>

                <!-- STEP #2 TAB -->
                <Question_2
                    v-if="currentQuestion === 2"
                    :options="secondStepOptions"
                    :category-data="categoryData"
                    @step-two-answer="secondStepAnswerSelected"
                ></Question_2>

                <!-- STEP #3 TAB -->
                <Question_3
                    v-if="currentQuestion === 3"
                    :cover-type="measureType"
                    @step-three-answer="thirdStepAnswerCalculated"
                ></Question_3>

                <!-- STEP #3 TAB -->
                <Question_4
                    v-if="currentQuestion === 4"
                    :calculation="result.toString()"
                    :product="selectedProduct"
                    @restart="restartCalculator"
                ></Question_4>

                <!-- CALCULATOR RESTART BUTTON -->
                <v-row
                    v-if="currentQuestion !== 4"
                    class="no-gutters noSpace" justify="center" align="center">
                    <v-btn
                        class="centuryGothicBold infoTableButton"
                        color="primary"
                        elevation="6"
                        style="margin-top: 40px; padding: 20px 40px;"
                        dark
                        @click="restartCalculator"
                    >Reiniciar Calculadora
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>



    </v-col>
</template>

<script>
import database from '../../constants/calculator_database.json';
import StepsWidget from "../../components/surveys/StepsWidget";
import Question_1 from "./sections/Question_1";
import Question_2 from "./sections/Question_2";
import Question_3 from "./sections/Question_3";
import Question_4 from "./sections/Question_4";

export default {
    name: "Calculator",
    components: {
        StepsWidget,
        Question_1,
        Question_2,
        Question_3,
        Question_4,
    },
    data() {
        return {
            categories: database.steps,
            categoryData: null,
            measureType: 2,
            currentQuestion: 1,
            finishedOne: false,
            finishedTwo: false,
            finishedThree: false,
            finishedFour: false,
            firstStepOptions: [],
            secondStepOptions: [],
            firstStepAnswer: null,
            secondStepAnswer: null,
            thirdStepAnswer: null,
            selectedProduct: null,
            result: 0,
        }
    },
    beforeMount() {
        this.colorFinishedQuestions();

        this.categories.forEach((element) => {
            this.firstStepOptions.push(element.title);
        });
    },
    methods: {
        firstStepAnswerSelected(value) {
            this.categoryData = this.categories.find(element => element['title'] === value);
            this.firstStepAnswer = this.categoryData['title'];
            this.measureType = this.categoryData['measure']

            Object.entries(this.categoryData.options).forEach((product) => {
                this.secondStepOptions.push(product[0]);
            });

            this.changeToQuestion(2);
        },

        secondStepAnswerSelected(value) {
            this.secondStepAnswer = value;
            this.selectedProduct = this.categoryData.options[value]["product"];

            this.changeToQuestion(3);
        },

        thirdStepAnswerCalculated(value) {
            this.thirdStepAnswer = value;

            let factor = this.categoryData.options[this.secondStepAnswer]["factor"];

            this.result = this.thirdStepAnswer * factor;
            this.result = parseFloat(this.thirdStepAnswer * factor).toFixed(2);

            this.changeToQuestion(4);
        },

        changeToQuestion(newQuestion) {
            this.currentQuestion = newQuestion;
            this.colorFinishedQuestions();
        },

        colorFinishedQuestions() {
            if (this.currentQuestion <= 1) {
                this.finishedOne = true;
                this.finishedTwo = false;
                this.finishedThree = false;
                this.finishedFour = false;

            } else if (this.currentQuestion === 2) {
                this.finishedOne = true;
                this.finishedTwo = true;

            } else if (this.currentQuestion === 3) {
                this.finishedOne = true;
                this.finishedTwo = true;
                this.finishedThree = true;

            } else if (this.currentQuestion === 4) {
                this.finishedOne = true;
                this.finishedTwo = true;
                this.finishedThree = true;
                this.finishedFour = true;
            }
        },

        restartCalculator() {
            this.firstStepAnswer = null
            this.secondStepAnswer = null
            this.thirdStepAnswer = null
            this.secondStepOptions = []
            this.selectedProduct = null
            this.changeToQuestion(1);
        },
    },
}
</script>

<style scoped>
.questionsSpace {
    height: auto;
}

@media (max-width: 960px) {
    .questionsSpace {
        height: auto;
    }
}
</style>