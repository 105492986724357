<template>
    <v-radio-group
        v-model="selectedOption"
        @change="sendSelectionToParent()"
        column
    >
        <v-radio
            v-for="(answer, index) in possibleAnswers"
            :key="'answer-' + index"
            class="custom-radios"
            on-icon="mdi-checkbox-blank-circle"
            :label="answer"
            color="primary"
            :value="answer"
        ></v-radio>
    </v-radio-group>
</template>

<script>
export default {
    name: "AnswersGroups",
    props: {
        radioGroupModel: String,
        possibleAnswers: Array,
    },
    data() {
        return {
            selectedOption: this.radioGroupModel
        }
    },
    methods: {
        sendSelectionToParent() {
            this.$emit('selected-option', this.selectedOption);
        }
    }
}
</script>

<style scoped>

</style>