<template>
    <v-col class="noSpace no-gutters">
        <!-- QUESTION -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-10">
            <v-col class="noSpace no-gutters">
                <!-- QUESTION TITLE -->
                <p class="centuryGothicBold questionText">¿Qué quieres hacer?</p>
            </v-col>
        </v-row>

        <!-- ANSWERS OPTIONS -->
        <v-row align="center" justify="center" class="noSpace no-gutters">
            <AnswersGroups
                :radio-group-model="radioButtonExample"
                :possible-answers="possibleAnswers"
                @selected-option="chooseStepOneAnswer"
            ></AnswersGroups>
        </v-row>
    </v-col>
</template>

<script>
import AnswersGroups from "../../../components/surveys/AnswersGroups";

export default {
    name: "Question_1",
    components: {
        AnswersGroups,
    },
    props: {
        options: Array,
    },
    data() {
        return {
            radioButtonExample: "",
            possibleAnswers: this.options,
        }
    },
    methods: {
        chooseStepOneAnswer(value) {
            this.$emit('step-one-answer', value);
        }
    }
}
</script>

<style scoped>

</style>