<template>
    <v-col class="noSpace no-gutters">
        <!-- QUESTION -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-10">
            <v-col class="noSpace no-gutters">
                <!-- QUESTION TITLE -->
                <p class="centuryGothicBold questionText">¿Cuánto necesitas cubrir?</p>

                <!-- QUESTION SUBTITLE -->
                <p class="centuryGothicBold questionSubtitle">{{ coverType === 2 ? 'ÁREA (m²)' : 'VOLUMEN (m³)' }}</p>
            </v-col>
        </v-row>

        <!-- AREA/VOLUMEN CALCULATOR FORM -->
        <v-row align="center" justify="center" class="noSpace no-gutters">
            <WorkSizeCalculator
                :parameters-list="coverType === 2 ? ['ancho', 'largo'] : ['ancho', 'largo', 'alto']"
                @calculation="gotStepThreeAnswer"
            ></WorkSizeCalculator>
        </v-row>
    </v-col>
</template>

<script>

import WorkSizeCalculator from "../../../components/surveys/WorkSizeCalculator";

export default {
    name: "Question_3",
    components: {
        WorkSizeCalculator,
    },
    props: {
        coverType: {
            type: Number,
            default: 2,
        },
    },
    data() {
        return {

        }
    },
    methods: {
        gotStepThreeAnswer(value) {
            this.$emit('step-three-answer', value);
        }
    }
}
</script>

<style scoped>

</style>