<template>
    <v-col class="noSpace no-gutters">
        <!-- RESULT -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-8">
            <v-col class="noSpace no-gutters">
                <!-- RESULT TITLE -->
                <p class="centuryGothicBold questionText">
                    Necesitas <span class="primary--text">{{ amount }}</span> sacos de <br>
                    <span class="primary--text">{{ productName }}</span> <br>
                    para realizar tu trabajo.
                </p>

                <!-- RESULT ESTIMATES NOTE -->
                <p class="centuryGothic primary--text mx-0 mx-lg-16 px-4 px-lg-16" style="text-align: center; font-size: 0.9em">
                    <b>*Importante:</b> El desempeño y rendimiento de los productos es susceptible a variaciones debido a las condiciones particulares de
                    cada proyecto, factores climáticos, revenimiento al que se preparó, características y nivelación del sustrato, formato de loseta
                    cerámica, o de las prácticas de instalación, según sea el caso. Es responsabilidad del cliente verificarlos y definirlos en cada obra.
                </p>

                <!-- CONTACT NOTE -->
                <p class="centuryGothic mx-0 mx-lg-16 px-4 px-lg-16" style="text-align: center;">
                    CONTACTA A UNO DE NUESTROS DISTRIBUIDORES OFICIALES DE PRODUCTOS MEZCLA BRAVA <br> PARA ENCONTRAR TODO PARA TU OBRA.
                </p>
            </v-col>
        </v-row>

        <!-- CTA BUTTONS -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-n4 px-4">
            <v-col class="noSpace no-gutters" cols="12" lg="4">
                <!-- GO TO DEALERS BUTTON -->
                <router-link
                    to="/dealers"
                    style="text-decoration: none !important;"
                >
                    <v-btn
                        id="detailsTableButton"
                        color="primary"
                        class="centuryGothicBold infoTableButton"
                        dark
                        elevation="6"
                        style="margin-top: 20px;"
                    >Ver distribuidores</v-btn>
                </router-link>

                <!-- RESTART CALCULATOR BUTTON -->
                <v-btn
                    id="detailsTableButton"
                    color="secondary"
                    class="centuryGothicBold infoTableButton"
                    elevation="6"
                    style="margin-top: 20px;"
                    outlined
                    @click="restartCalculator"
                >Reiniciar Calculadora</v-btn>
            </v-col>
        </v-row>

        <!-- BIG PROJECTS NOTE -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-8">
            <v-col class="noSpace no-gutters">
                <p class="centuryGothicBold questionText mx-0 mx-lg-16 px-4 px-lg-16">
                    Si tu obra requiere alto volumen de material contacta a un asesor para que nuestro equipo de especialistas te brinde apoyo
                    técnico y logístico.
                </p>
            </v-col>
        </v-row>

        <!-- BIG PROJECTS CONTACT BUTTON -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-n4 px-4">
            <v-col class="noSpace no-gutters" cols="12" lg="4">
                <router-link
                    to="/contact"
                    style="text-decoration: none !important;"
                >
                    <v-btn
                        color="secondary"
                        class="centuryGothicBold infoTableButton"
                        dark
                        elevation="6"
                        style="margin-top: 20px;"
                    >CONTACTO</v-btn>
                </router-link>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "Question_4",
    props: {
        calculation: String,
        product: String,
    },
    data() {
        return {
            amount: this.calculation,
            productName: this.product
        }
    },
    methods: {
        restartCalculator() {
            this.$emit('restart');
        }
    }
}
</script>

<style scoped>
.questionText {
    line-height: 30px;
    font-size: 1.4em;
}

.infoTableButton {
    width: 100%;
}

.infoTableButton:hover {
    opacity: 0.9;
}
</style>