<template>
    <v-col class="noSpace no-gutters">
        <!-- QUESTION -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-10">
            <v-col class="noSpace no-gutters">
                <!-- QUESTION TITLE -->
                <p class="centuryGothicBold questionText">{{ selectionData.question }}</p>

                <!-- QUESTION SUBTITLE -->
                <p class="centuryGothicBold questionSubtitle">{{ selectionData.family }}</p>
            </v-col>
        </v-row>

        <!-- ANSWERS OPTIONS -->
        <v-row align="center" justify="center" class="noSpace no-gutters px-8">
            <AnswersGroups
                :radio-group-model="radioButtonExample"
                :possible-answers="possibleAnswers"
                @selected-option="chooseStepTwoAnswer"
            ></AnswersGroups>
        </v-row>
    </v-col>
</template>

<script>
import AnswersGroups from "../../../components/surveys/AnswersGroups";

export default {
    name: "Question_2",
    components: {
        AnswersGroups,
    },
    props: {
        options: Array,
        categoryData: Object,
    },
    data() {
        return {
            radioButtonExample: "",
            possibleAnswers: this.options,
            selectionData: this.categoryData
        }
    },
    methods: {
        chooseStepTwoAnswer(value) {
            this.$emit('step-two-answer', value);
        }
    }
}
</script>

<style scoped>

</style>