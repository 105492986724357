<template>
    <v-col class="no-gutters noSpace px-6">
        <!-- SIDES TEXT FIELDS -->
        <v-row class="no-gutters noSpace" align="center" justify="center">
            <!-- TEXT FIELD 1 -->
            <v-col class="px-0 px-lg-4 no-gutters noSpace" cols="12" lg="2">
                <p class="centuryGothicBold mb-1" style="text-align: center;">
                    {{ parametersList[0].toString().toUpperCase() }} (m)
                </p>

                <v-text-field
                    v-model="value1"
                    dense
                    color="secondary"
                    outlined
                ></v-text-field>
            </v-col>

            <!-- TEXT FIELD 2 -->
            <v-col class="px-0 px-lg-4 no-gutters noSpace" cols="12" lg="2">
                <p class="centuryGothicBold mb-1" style="text-align: center;">
                    {{ parametersList[1].toString().toUpperCase() }} (m)
                </p>

                <v-text-field
                    v-model="value2"
                    dense
                    color="secondary"
                    outlined
                ></v-text-field>
            </v-col>

            <!-- TEXT FIELD 3 -->
            <v-col v-if="parametersList.length === 3" class="px-0 px-lg-4 no-gutters noSpace" cols="12" lg="2">
                <p class="centuryGothicBold mb-1" style="text-align: center;">
                    {{ parametersList[2].toString().toUpperCase() }} (m)
                </p>

                <v-text-field
                    v-model="value3"
                    dense
                    color="secondary"
                    outlined
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- CALCULATION REQUEST BUTTON -->
        <v-row align="center" justify="center" class="noSpace no-gutters mt-n8 px-4 mb-4">
            <v-col class="noSpace no-gutters" cols="12" lg="4">
                <v-btn
                    id="detailsTableButton"
                    color="primary"
                    class="centuryGothicBold"
                    dark
                    elevation="6"
                    style="margin-top: 20px; width: 100%"
                    @click="doCalculation()"
                >Calcular</v-btn>
            </v-col>
        </v-row>

        <!-- VISUAL AID -->
        <v-row class="no-gutters noSpace" align="center" justify="center">
            <v-col class="no-gutters noSpace" cols="12" lg="6" align-self="center" align="center">
                <!-- AREA DIAGRAM -->
                <v-img
                    max-width="400"
                    v-if="parametersList.length === 2"
                    :src="require('../../assets/diagrams/area.png')"
                ></v-img>

                <!-- VOLUME DIAGRAM -->
                <v-img
                    height="auto"
                    max-width="400"
                    v-if="parametersList.length === 3"
                    :src="require('../../assets/diagrams/volumen.png')"
                ></v-img>
            </v-col>
        </v-row>

        <!-- INVALID FORM -->
        <v-bottom-sheet v-model="bottomSheetTrigger">
            <v-sheet
                class="text-center"
                height="150px"
                color="primary"
            >
                <v-btn
                    class="mt-6 centuryGothicBold"
                    color="white"
                    elevation="0"
                    outlined
                    @click="bottomSheetTrigger = !bottomSheetTrigger"
                >
                    CERRAR
                </v-btn>
                <div class="mt-4">
                    <h4 class="centuryGothic" style="color: white;">Debes llenar los campos únicamente con números decimales.</h4>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-col>
</template>

<script>
export default {
    name: "WorkSizeCalculator",
    props: {
        parametersList: Array,
    },
    data() {
        return {
            value1: null,
            value2: null,
            value3: null,
            calculation: 0,
            bottomSheetTrigger: false
        }
    },
    methods: {
        doCalculation() {
            if(this.validateFields() === true) {
                if(this.value3 !== null) {
                    this.calculation = this.value1 * this.value2 * this.value3;
                } else {
                    this.calculation = this.value1 * this.value2;
                }

                this.clearFields();

                this.$emit('calculation', this.calculation);

            } else {
                this.bottomSheetTrigger = true;
            }
        },

        validateNumber(value) {
            return !isNaN(value - parseFloat(value));
        },

        validateFields() {
            let result = false;
            if(this.value3 !== null) {
                if(this.validateNumber(this.value1) === true && this.validateNumber(this.value2) === true && this.validateNumber(this.value3) === true) {
                    result = true;
                }
            } else {
                if(this.validateNumber(this.value1) === true && this.validateNumber(this.value2) === true) {
                    result = true;
                }
            }


            return result;
        },

        clearFields() {
            this.value1 = this.value2 = this.value3 = null;
        }
    }
}
</script>

<style scoped>

</style>